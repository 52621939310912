<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Movimientos en almacenes </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed" v-model="personalTab">
      <b-tab-item
        label="Movimientos en almacenes (Kardex)"
        icon="warehouse"
        :visible="
          companyPaymentPlanModules.purchase.getWarehouseMovements === 'S'
        "
      >
        <WarehousesTransfers type="1"></WarehousesTransfers>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import WarehousesTransfers from "../components/WarehousesTransfers";
export default {
  name: "WarehouseTransfersComponents",
  components: {
    WarehousesTransfers,
  },
  data() {
    return {
      personalTab: 0,
    };
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "warehousesMovements");
    if (
      this.companyPaymentPlanModules.humanResources.getWarehouseMovements ===
      "N"
    ) {
      this.personalTab = 1;
    }
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
